@import '../node_modules/bootstrap/scss/bootstrap.scss';

.themeWhiteWrapper {
	@extend .container;
}

.themeWhiteWrapper-fluid {
	@extend .container-fluid;
}

.themeWhite-WrapRow {
	@extend .row;
}
