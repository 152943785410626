@import "./vars";

/*---- mixins ----*/
@mixin scrollbars($size, $foreground-color, $foreground-color-hover, $background-color: mix($foreground-color, white,  50%), $radius: 0) {
  // Usage: @include scrollbars(10px, #1f1f1f, #5e738b, #f1f1f1, 0);
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $radius;
    background: $foreground-color;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $foreground-color-hover;
  }

  &::-webkit-scrollbar-track {
    border-radius: $radius;
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
/*---- mixins END ----*/

/*Border Component END*/
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Exo 2', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  @include scrollbars(15px, #e73c7e, #23d5ab, #23a6d5, 0);
  counter-reset: counter-name-popular;

  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;


}

.auth-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-11);
  input{
    height: 50px;
    background-color: var(--color-11);
    border: 1px solid var(--color-white);
    color: var(--color-white);
    margin-right: 10px;
    font-size: 20px;
    padding-left: 5px;
    &::placeholder{
      padding-left: 5px;
      color: var(--color-white);
    }
  }
}

.resultsTractListWrapper {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  a.button {
    align-self: center;
    padding: 20px;
    background-color: #2c3e50;
    color: var(--color-white);
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 0;
  }

  a.share {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 100%;
    box-shadow: var(--color-background) 0 1px 0;

    i{
      font-size: 17px;
      color: var(--color-5);
      transition: all 0.5s;
    }

    &:hover {
      text-decoration: none;
      transition: all 0.5s;

      &::before,
      i {
        color: var(--color-1);
        transition: all 0.6s;
      }
    }
  }

  a.link {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 100%;
    &::before {
      transition: all 0.5s;
    }

    &:hover {
      text-decoration: none;
      transition: all 0.5s;

      &::before {
        color: var(--color-1);
        transition: all 0.6s;
      }
    }
  }
}

.auth-1 {
  padding: 10px;
  border-radius: 8px 8px 0 0;
  color: var(--color-white);
}

.auth-2 {
  background-color: var(--color-1);
  padding: 0 10px;
}

.auth-3 {
  background-color: var(--color-1);
  padding: 10px;
  margin-bottom: 50px;
  border-radius: 0 0 8px 8px;
}

.colorBackgroundGradient {
  background: var(--background-gradient);
}

.themeWhiteWrapper {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}

.menu-top {
  padding-top: 20px;
}

.musicTop-head {
  background-color: #3699ff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.shadowBox {
  box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
}

.footerBox-bottom {
  position: relative;
}

.footerBox {
  .themeWhite-WrapRow {
    width: 100%;
  }
}

.box-menu-padding-0 {
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.block-title {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.popularTrackBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.themeWhite--SocialBox {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.themeWhite--SocialBox--btnBox {
  position: relative;
  padding: 10px;
  border: 1px solid;
  border-color: var(--color-2);
  border-radius: 10px;
}

.logo {
  min-height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: center;

  a {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    line-height: normal;
    color: var(--color-2);
    text-decoration: none;
    font-size: 50px;
    padding: 5px;

    &:hover {
      i {
        transition: all 0.6s;
      }
    }

    i {
      margin-right: 10px;
      transition: all 0.5s;
    }
  }
}

.whiteCustomFontFamily {
  font-family: var(--custom-font-family);
}

.transform-rotateBox {
  transition: all 0.5s;

  &:hover {
    transform: rotate(-30deg);
    transition: all 0.6s;
  }
}

.musicSearch-contaniner {
  height: auto;

  input {
    -webkit-appearance: none;
    outline: none;
  }
}

.themeWhite-SearchContainer {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;

  button {
    -webkit-appearance: none;
    outline: none;
  }

  #codeint {
    display: none;
  }
}

.themeWhite-SearchContainerBox {
  box-sizing: border-box;
  width: 100%;
  min-width: 300px;
  border: 15px solid var(--color-whiteThemeTransparent-1);
  padding: 10px;
  border-radius: 30px;
}

.inp {
  padding-right: 40px;
  background-color: transparent;
}

.string {
  box-sizing: border-box;
  width: 100%;
  border: 0;
  padding: 7px 10px;
  font-size: 14px;
  background-color: var(--color-11);
  color: var(--color-2);
  height: 40px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  &::placeholder{
    color: var(--color-2);
  }

  &:focus {
    outline: none;
  }
}

.searchArtist {
  position: absolute;
  top: 0;
  right: 80px;
  font-size: 14px;
  padding: 8px 7px;
  width: 144px;
  outline: none;
  background-color: #e7e7e7;
  cursor: pointer;
  color: #656565;
  border: 0;
  transition: all 0.2s ease;

  option {
    &:hover {
      background-color: #e7e7e7;
      color: #656565;
    }
  }
}

.siteTopWrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--color-6);
}

#trackAjax {
  margin-top: 15px;
  margin-bottom: 10px;
  z-index: 1;

  .themeWhite-WrapRow {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
}

.themeWhite-leftHeaderWrap {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  padding: 7px 0;
  width: 40px;
  cursor: pointer;
  color: var(--color-white);
  transition: all 0.2s ease;
  border: none;
  height: 100%;
  background: var(--color-11);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  i {
    margin-right: 3px;
    transition: all 0.5s;
  }

  &:hover {
    i {
      transition: all 0.6s;
    }
  }
}

#searchform {
  position: relative;
}

.navbar-dark {
  .navbar-brand {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-2);
    text-shadow: 1px 1px 0 var(--color-white), 2px 2px 0 var(--color-2);
  }

  .navbar-nav {
    > li {
      > a {
        color: var(--color-white);
      }
    }
    > .nav-item{
      .nav-link {
        display: flex;
        align-items: center;
        height: 50px;
        color: var(--color-white);
        position: relative;
        &::before{
          display: inline-block;
          position: absolute;
          content: '';
          right: -1px;
          width: 2px;
          height: 50%;
          background-color: var(--color-1);
          top: calc(25%);
        }
        &.home-link{
          display: flex;
          align-items: center;
          justify-content: center;
          i{

          }
          span{
            margin-left: 5px;
          }
        }

        &:focus {
          color: #333;
          background-color: var(--color-3);
        }

        &:hover {
          color: var(--color-white);
          background-color: var(--color-3);
        }
      }
      &:last-child{
        .nav-link{
          &::before{
            display: none;
          }
        }
      }
    }


    .show {
      .nav-link {
        color: var(--color-8);
        &.dropdown-toggle{
          color: var(--color-white);
        }

        &:focus {
          background-color: var(--color-3);
        }

        &:hover {
          background-color: var(--color-3);
        }
      }
    }

    .dropdown-menu {
      .nav-link {
        height: auto;
        padding: 3px 20px;
      }
    }
  }

  .navbar-toggler {
    border-color: #ddd;
  }
}

.navbar-brand {
  i {
    margin-right: 5px;
  }
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.navbar {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  padding: 0 1rem;
  border-radius: 0;
  border: none;
  background-color: var(--color-2);
  .navbar-collapse {
    .dropdown-menu {
      &.show {
        border-radius: 0;
      }
    }
  }
  .themeWhite--SocialBox--btnBox{
    border-color: var(--color-1);
  }
}

.navbar-toggler {
  position: relative;
  padding: 6px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
}

.menu-left {
  min-height: 120px;
}

.selected {
  background-color: #f64e60 !important;
  color: var(--color-white) !important;
  text-decoration: none;
}

.content-list-sounds-mew {
  margin-bottom: 20px;
}

.glyphicon {
  top: 0;
  vertical-align: text-top;
}

.white--siteFooter {
  padding: 30px 30px 30px;
  color: var(--color-2);

  p {
    span {
      font-family: var(--custom-font-family);
    }

    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  p.leftTextBoxLine {
    span {
      margin-right: 3px;
      margin-left: 6px;

      &.whiteCustomFontFamily{
        font-size: 20px;
      }
    }
  }

  .white--siteFooter--infoBox {
    width: fit-content;
    text-align: right;
    font-family: var(--custom-font-family);
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
}

.mail-to{
  a{
    color: var(--color-2);
  }
}
.footerTapLink {
  font-size: 25px;
  color: var(--color-5);

  &:hover {
    text-decoration: none;
    color: var(--color-5);
  }
}

.t-align-right {
  text-align: right;
}

.margin-bottom-60px {
  margin-bottom: 60px;
}

.list-search {
  display: none;
}

.themeWhite-leftHeaderWrap-site {
  margin-top: 150px;
}

.whitePlayerBoxWrapper {
  z-index: 99999;
  top: auto;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 0 auto;
  height: 120px;
  padding: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background: var(--color-11);
  transition: all 0.6s ease;
}

.whitePlayerWrap--volumeBox {
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  display: inline-block !important;
  width: 100%;
  height: 14px;
  background: var(--color-white);
  vertical-align: top;
  float: right;
  margin-top: 0;
  border: 2px solid var(--color-white);

  .whitePlayerWrap--volumeLine {
    cursor: pointer;
    width: 40%;
    height: 10px;
    float: left;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
    background: var(--color-2);
  }
}

.artist-one {
  font-size: 18px;
  font-weight: 700;
}

.artist-two {
  font-size: 18px;
  font-weight: 700;
}

.themeWhite-popularTrackTitleContainer {
  width: 100%;
  padding: 10px;

  p {
    margin: 15px 0;
    color: var(--color-5);
  }
}

.service{
  .btn-outline-light{
    padding: .75rem 1.25rem;
  }
}

.description-box{
  margin-top: 6px;
  margin-bottom: 20px;
  width: 100%;
  background-color: var(--color-whiteThemeTransparent-1);
  padding: 10px;
  border-radius: 10px;
  p {
    margin: 15px 0;
    color: var(--color-5);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span{
      margin: 0 5px;
      &::before{
        content: open-quote;
        color: var(--color-white);
        margin-right: 5px;
      }
      &::after{
        content: close-quote;
        color: var(--color-white);
        margin-left: 5px;
      }
    }
    i{
      font-size: 25px;
      margin-right: 10px;
    }
  }
}
.popular-container_background{
  background-color: var(--color-whiteThemeTransparent-1);
  padding: 10px 10px 0 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  h2{
    color: var(--color-2);
    margin: 0;
  }

  .popular-container{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    a{
      &.popular-track_box{
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 50px;
        min-width: 100px;
        max-width: 200px;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--color-white);
        color: var(--color-white);
        margin: 8px 5px 8px 10px;
        padding: 0 5px 0 30px;
        border-radius: 10px;
        position: relative;

        &:hover{
          text-decoration: none;
        }
        .popular-track_box--image{
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--color-white);
          position: absolute;
          left: -10px;
          top: -10px;
          margin: 0;
          background-color: var(--color-11);
          border-radius: 50%;

          .counter-box{
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            text-align: center;
            box-shadow: 0 0 8px -6px #4f5abc;
            border: 1px solid var(--color-white);
            &::before{
              width: 40px;
              height: 40px;
              line-height: 40px;
              counter-increment: counter-name-popular;
              content: counter(counter-name-popular);
              background: 0 0;
              font-weight: 700;
              color: var(--color-white);
              font-family: var(--custom-font-family);
            }
          }

          i{
            font-size: 30px;
          }
        }
        .popular-track_box--title{
          height: 25px;
          overflow: hidden;
          line-height: 25px;
          font-size: 15px;
          width: 100%;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}


.themeWhite-popularTrackLine {
  margin: 15px 0;
  font-weight: 700;
  color: var(--color-2);

  i {
    margin-right: 5px;
  }
  span{

    &::before{
      content: open-quote;
      color: var(--color-white);
      margin-right: 10px;
    }
    &::after{
      content: close-quote;
      color: var(--color-white);
      margin-left: 10px;
    }
  }
}

.seoTitleBox {
  p {
    span {
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

.resultTrackHeaderContainer {
  display: flex;
  align-items: center;
  min-height: 40px;
  border-bottom: 1px solid var(--color-5);
  margin-bottom: 10px;
  font-size: 20px;
}

.play-box {
  width: 50px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.track-name-box {
  width: calc(100%);
  color: var(--color-6);
  //height: 20px;
}

.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.themeWhiteTrackBoxResult {
  height: 60px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  cursor: default !important;
  position: relative;
  transition: all 0.15s ease;
  margin-bottom: 10px;
  background: var(--color-11);
  border-radius: 10px;

  &:last-child {
    margin-bottom: 14px;
  }

  &:hover {
    background: var(--color-13);

    h2 {
      .title {
        color: var(--color-white);

        a {
          color: var(--color-white);
        }
      }
    }

    .left-b {
      color: var(--color-white);
    }

    a.share {
      &::before,
      i{
        color: var(--color-white);
      }
    }

    a.link {
      &::before,
      i{
        color: var(--color-white);
      }
    }
  }

  a.start {
    cursor: pointer;
    width: 38px;
    height: 38px;
    background-color: var(--color-2);
    border: 1px solid var(--color-2);
    transition: all 0.8s ease;
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: var(--color-13);
    border-radius: 50%;

    &::before {
      position: relative;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e072';
      margin-left: 2px;
    }

    &:hover {
      background-color: var(--color-3);
      transition: all 0.2s ease;
      text-decoration: none;
      &::before{
        color: var(--color-2);
      }
    }
  }

  a.link {

    i{
      font-size: 22px;
      color: var(--color-2);
    }

    &:hover {
      text-decoration: none;
      transition: all 0.2s ease;
      i{
        color: var(--color-white);
      }
    }
  }

  h2 {
    font-size: 1em;
    margin: 0 0 0 10px;
    line-height: normal;
    overflow: hidden;
    width: calc(100% - 143px);
    align-self: stretch;
  }
}

.themeWhiteTrackBoxResult.pausing {
  a.start {
    &::before {
      position: relative;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e072';
      margin-left: 2px;
    }
  }

  background: var(--color-14);

  h2 {
    .title {
      color: var(--color-white);

      a {
        color: var(--color-white);
      }
    }
  }

  .left-b {
    color: var(--color-white);
  }

  a.share {
    &::before,
    i{
      color: var(--color-white);
    }
  }

  a.link {
    &::before,
    i{
      color: var(--color-white);
    }
  }
}

.themeWhiteTrackBoxResult.plays {
  a.start {
    &::before {
      content: '\e073';
      transform: rotate(0deg);
    }
  }

  background: var(--color-14);

  .start {
    border: 1px solid var(--color-14);
  }

  .pausing {
    border: 1px solid var(--color-14);
  }

  h2 {
    .title {
      color: var(--color-white);

      a {
        color: var(--color-white);
      }
    }
  }

  .left-b {
    color: var(--color-white);
  }

  a.share {
    &::before,
    i{
      color: var(--color-white);
    }
  }

  a.link {
    &::before,
    i{
      color: var(--color-white);
    }
  }
}

.left-b {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  color: var(--color-2);
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40px;

  .dur {
    margin-right: 0;
    font-weight: 700;
    position: relative;
    line-height: normal;

    &::before {
      position: relative;
      margin-right: 5px;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #3699ff;
    }
  }
}
.shareDownload_Wrap{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  width: 50px;
  border-left: 1px solid var(--color-background);
  background-color: var(--color-13);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}


h2 {
  .title {
    font-weight: normal;
    color: #545454;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    > span{
      color: var(--color-white);
    }

    a {
      color: var(--color-white);
      font-weight: 700;

      &:first-child {
        padding-right: 3px;
      }

      &:last-child {
        padding-left: 3px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

a.play {
  color: var(--color-white);

  &:hover {
    text-decoration: none;
  }
}

a.pause {
  color: var(--color-white);
  border: none;

  &:hover {
    text-decoration: none;
  }
}


.play,
.pause{
  width: 54px;
  height: 54px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 30px;
  background-color: var(--color-11);
  border-radius: 50%;
  &:hover{
    background-color: transparent;
  }
  &::before{
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: 3px;
  }

}
.play {
  &::before {
    content: '\e072';
  }
}
.pause {
  border: 1px solid var(--color-2);
  &::before {
    content: '\e073';
  }
}

.whitePlayerWrap--PlayPauseWrap-backBtn,
.whitePlayerWrap--PlayPauseWrap-forwardBtn{
  width: 38px;
  height: 100%;
  margin: 0 -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 25px;
  background-color: var(--color-11);
  &:hover{
    background-color: var(--color-13);
    transition: all 1.2s ease;
  }

  &::before{
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.whitePlayerWrap--PlayPauseWrap-backBtn{
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  &::before{
    //content: '\e079';
  }
}
.whitePlayerWrap--PlayPauseWrap-forwardBtn{
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  &::before{
    //content: '\e080';
  }
}

.whitePlayerWrap--PlayPauseWrap {
  width: 160px;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: var(--color-13);
}

.whitePlayerWrap--ProgressBarWrapper {
  cursor: pointer;
  transition: height linear 0.3s;
  position: absolute;
  top: 0;
  bottom: auto;
  height: 10px;
  background-color: var(--color-whiteTheme-3);
  right: 0;
  color: #000;
  font-size: 10px;
  width: 100%;
  z-index: 3;
}

.whitePlayerWrap--ProgressBarWrapper--downloadBar {
  display: block;
  height: 10px;
  width: 100px;
  z-index: 201;
}

.whitePlayerWrap--ProgressBarWrapper--playBar {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  height: 10px;
  background: var(--color-2);
  color: var(--color-2);
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: var(--color-white);
    border: 2px solid #061161;
    display: none;
  }
}

.whitePlayerWrap {
  position: relative;
  //height: calc(100% - 20px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0 0;
}

.whitePlayerWrap--trackTimeBox {
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
  width: calc(200px);
  position: relative;
  //left: calc(50% - 100px);
  //top: calc(50% + 18px);
  > span {
    i {
      margin-right: 5px;
    }
  }
}

.whitePlayerWrap--trackTimeBox--currentTime {
  line-height: normal;
  font-size: 25px;
  font-family: var(--custom-font-family);
  text-align: right;
  left: 0;
  top: auto;
  color: var(--color-1);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.whitePlayerWrap--trackTimeBox--duration {
  line-height: normal;
  font-size: 25px;
  font-family: var(--custom-font-family);
  text-align: right;
  right: 0;
  left: auto;
  color: var(--color-1);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.whiteCustomFontFamily{
  font-family: var(--custom-font-family);
}

.playerTrack {
  display: block;
  margin-left: 0;
  font-size: 14px;
  overflow: hidden;
  color: var(--color-white);
  max-width: calc(250px - 55px);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.whitePlayerWrap--rightActionBox {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  justify-content: space-around;
  padding: 0 10px;
  .themeWhite--SocialBox{
    .themeWhite--SocialBox--btnBox{
      border-color: var(--color-white);
    }
  }

  &-muteUnmute{
    width: 230px;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.loaded {
  display: none;
}

.uil {
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transition: all 1.2s ease;
  }
}

a.uil {
  color: var(--color-white);

  &:hover {
    text-decoration: none;
  }
}

.uil.pause {

  &:hover {
    background-color: transparent;
  }
}

.uil.whitePlayerWrap--PlayPauseWrap-forwardBtn {
  border-left: none;
  border-bottom: none;
}

.uil.whitePlayerWrap--PlayPauseWrap-backBtn {
  border-right: none;
  border-top: none;
}

.whitePlayerWrap--leftWrapper {
  height: 100%;
  width: 250px;
  .player__sidebarCollapse{
    display: none;
  }

  .playerTrack__box{
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .playerTime__box{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .themeWhite--SocialBox{
    display: none;
  }
}

.playerTrack--icon {
  width: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-right: 8px;
  &.spin{
    i{
      -webkit-animation:spin 4s linear infinite;
      -moz-animation:spin 4s linear infinite;
      animation:spin 4s linear infinite;
    }
  }

  i {
    color: var(--color-white);
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  width: 40px;
  height: 24px;
  margin-right: 5px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &:hover {
    opacity: 1;
  }
}

.repeat {
  margin-right: 30px;

  &::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.repeatOff {
  &::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  margin-right: 30px;
  opacity: 1;
}


.mute,
.unmute{
  &::before{
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    color: var(--color-white);
  }
}
.mute {
  &::before {
    content: '\e038';
  }
}
.unmute {
  &::before {
    content: '\e036';
  }
}

.themeWhite-leftHeaderWrap--socialBox {
  min-width: 50%;
}

.janry-musiki {
  text-align: center;
}

.site-left-gorizont-menu {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.nav-stacked {
  > li {
    display: block;
    border-bottom: 1px solid var(--color-6);

    + {
      li {
        margin-top: 0;
      }
    }
  }
}

.themeWhite-Aside{
  .themeWhite-AsideBox{
    margin-bottom: 20px;
    &--title{
      h2{
        margin: 15px 0;
        font-weight: 700;
        color: var(--color-2);
      }
    }
    &--content{
      .genres{
        flex-direction: row;
        flex-wrap: wrap;
        .list-group-item{
          border: none;
          width: calc(50% - 2px);
          margin: 1px;
          border-radius: 4px;
        }
      }
    }
  }
}

.list-sounds-new {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.nav {
  > li {
    > a {
      color: #444;
    }
  }
}

.footerSidebar {
  margin-bottom: 15px;
}

.themeWhite-navigationBox{
  margin-bottom: 40px;
  .themeWhite-navigationBox-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    &.top{

    }
    .collapse{
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      .themeWhite-navigationBox-wrapper--wrap{
        display: flex;
      }
      &.show{
        display: flex;

        .themeWhite-navigationBox-wrapper--wrap{
          .themeWhite-navigationBox-wrapper--wrap-content{
            min-height: 50px;
          }
        }
      }
    }
    .themeWhite-navigationBox-wrapper--wrap{
      margin: 2px;
      border: 5px solid var(--color-whiteTheme-3);
      color: var(--color-white);
      border-radius: 15px;
      &:hover{
        text-decoration: none;
        border-color: var(--color-whiteTheme-3-hover);
        .themeWhite-navigationBox-wrapper--wrap-content{
          background-color: var(--color-whiteTheme-3-hover);
        }
      }

      .themeWhite-AsideBox--content & {
        border: none;
        .themeWhite-navigationBox-wrapper--wrap-content{
          flex-direction: row;
          min-height: 50px;
          height: 50px;
          margin: 0;
          width: 120px;
          border-radius: 4px;
          i{
            font-size: 16px;
          }
          .title{
            font-size: 13px;
            text-align: center;
          }
        }
      }

      .themeWhite-navigationBox-wrapper--wrap-content{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 140px;
        min-height: 70px;
        margin: 5px;
        background-color: var(--color-whiteTheme-3);
        border-radius: 8px;

        i{
          font-size: 30px;
        }
        .title{
          font-size: 16px;
        }
      }
    }
  }
}

.verticalLine{
  &::before{
    content: '';
    position: absolute;
    top: 10%;
    right: -1px;
    width: 2px;
    height: 80%;
    background-color: #d2d2d2;
  }
}

.headerWrapper {
  position: relative;
  &--alignItems{
    align-items: center;
  }
}

.menu-box {
  position: relative;
  top: 10px;
}

.height-100P {
  height: 100%;
}

.background-color {
  background-color: var(--color-background);
}

.whiteFooterWrapper {
  position: relative;
  background: var(--color-whiteThemeTransparent-1);
  margin-bottom: 120px;
}

#whitePlayerBoxWrapper,
#toTop{
  display: none;
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--color-3);
    }

    &:hover {
      background-color: var(--color-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--color-3);
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.copyright_box{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}


/*SHAREBOX*/
span{
  &.shareblock{
    z-index: 100;
    max-width: 40%;
    display: block;
    background-color: var(--color-white);
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -200px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    border: 1px solid var(--color-2);
    b {
      position: absolute;
      top: -13px;
      right: -13px;
      display: block;
      width: 27px;
      height: 27px;
      text-align: center;
      margin-bottom: -24px;
      background-color: #dddddd;
      cursor: pointer;
      border-radius: 30px;
      line-height: 26px;
    }
    h1 {
      padding: 10px 15px;
      margin: 0;
      background: var(--color-2);
      font-family: sans-serif;
      font-size: 20px;
      font-weight: 400;
      display: block;
      color: var(--color-white);
      border-bottom: 8px solid var(--color-3);
    }
    span {
      display: block;
      overflow: hidden;
      padding: 20px;
    }
    a {
      text-decoration: underline;
      display: block;
      float: left;
      height: 30px;
      line-height: 30px;
      vertical-align: middle;
      font-size: 1.1em;
      background: #f2f2f2 url('../images/share.png') 5px 0 no-repeat;
      border-radius: 2px;
      margin: 10px 10px 0 0;
      padding: 0 10px 0 26px;
      font-family: sans-serif;

      &.vk {
        background-position: 5px 0;
      }
      &.tw {
        background-position: 5px -60px;
      }
      &.ok {
        background-position: 5px -90px;
      }
      &.mm {
        background-position: 5px -120px;
      }
      &.gp {
        background-position: 5px -150px;
      }
    }
  }
}



.bottom_header{
  color: var(--color-2);
  .textBoxLine{
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    .textBoxLine_top{
      .whiteCustomFontFamily{
        font-size: 23px;
      }
    }
    .textBoxLine_bottom{

    }
  }
}