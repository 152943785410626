:root{
    --color-1: #f1f1f1;
    --color-2: #FF9200;
    //--color-3: #A668D5;
    --color-3: #f3f6f9;
    //--color-4: #fff4e3;
    --color-4: var(--color-2);
    --color-5: var(--color-2);
    --color-6: var(--color-3);
    --color-7: transparent;
    --color-8: #333;
    --color-9: #f8f9fa;
    --color-10: #1b252f;
    --color-11: #033E6B;
    --color-background: #171E27;
    --color-13: #25567B;
    --color-14: #A65F00;
    --color-15: #2c3f53;
    --color-16: rgba(44, 63, 83, 0.5);
    --color-white: #fff;
    --color-whiteThemeTransparent-1: #1f273259;
    --color-whiteTheme-2: #1b252f;
    --color-whiteTheme-3: #0B61A4;
    --color-whiteTheme-3-hover: #3F92D2;

    --background-gradient: linear-gradient(to right, var(--color-1), var(--color-2)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    --background-gradient-inverse: linear-gradient(to right, var(--color-2), var(--color-1)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    --custom-font-family: 'Akronim', cursive;
}